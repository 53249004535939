<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="تعديل متبرع">
          <b-row> 
          <b-col xl="4" md="3">
            <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  label-for="title"
                  :state="errors.length > 0 ? false : null"
                >
            <label>الاسم</label>
            <b-form-input class="" v-model="name" />
          </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          <b-col md="3" xl="3">
                <b-form-group
                  label-for="code"
                  
                >
             
                <label> الرقم</label>
                <b-form-input v-model="number" type="number" id="input-default" />
              </b-form-group>
            </b-col>
              <b-col md="3" xl="3" >
                <b-form-group
                  label-for="brand"
                >
             
                <label>  التفاصيل</label>
                <b-form-input v-model="details" type="text" id="input-brand_name" />
              </b-form-group>
            </b-col>
          <br><br>
          </b-row>
        <b-row>  <b-col xl="2" >  <b-button variant="purple" @click="updateWork"><span>تعديل</span></b-button></b-col>
      
         
        </b-row> 
        </b-card-code>
      </b-form>
    </validation-observer>
    <b-col md="4" xl="2">
      <b-button variant="purple " @click="$router.go(-1)">
        <span class="align-middle">رجوع</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BFormGroup,
  BInputGroupPrepend,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BTab,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
  BTabs,
} from "bootstrap-vue";
export default {
 

  components: {
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BFormInvalidFeedback,
    VueGoodTable,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BTabs,
    BTab,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },

  data() {
    return {
     
      name: "",
      number:"",
      details:"",
    };
  },

  created() {
    // this.getWeak();
    this.getBiller()
   
  },

  methods: {
    getBiller() {
      this.$http.get(`/api/v1/donor/${this.$route.params.id}`).then((res) => {
      //  console.log( res.data.data)
        this.name=res.data.data.name
         this.number=res.data.data.number
         this.details=res.data.data.details
      });
    },
  

    updateWork() {
      let data={
        name:this.name,
        number: this.number,
        details:this.details
      }
      let url=`/api/v1/donor/${this.$route.params.id}`
       this.$http.put(url,data).then((res) => {
      
        //console.log(res);
         this.$toast({
          component: ToastificationContent,
          props: {
            title: "تم التعديل بنجاح",
           icon: "CheckIcon",
              variant: "success",
            },
          },
          {
            position: "top-center",
          });
      });
    },
 

  }
};
</script> 

<style scoped>
.form-control {
  font-weight: bold;
}
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-purple {
  color: white;
  background-color: #7e7a9f;
  margin-top: 25px;
}

label {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
  font-size: 20px;
}
.align-left {
  margin-left: 750px;
}
.but {
  top: 40px;
}
</style>